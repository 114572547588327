import LeetCodeLogo from "../images/leetcode.svg"

export const HomePage = () => {
    return(
        <div className="bodyDiv">
            <h1>Home</h1>

            <p>This website was created an excerise to learn a frontend web development framework. This website is meant to serve as a testing ground and as a way for me to showcase small level projects completed outside of the professional environment.</p>

            <form action="https://leetcode.com/JacksonSemmens/" method="get" target="_blank">
                <button type="submit" className="leetcodeButton"><img src={LeetCodeLogo} className="leetcodeLogo"></img> LeetCode Profile</button>
            </form> 
        </div>
    )
}