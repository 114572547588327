import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion"
import githubLogo from "../images/github.svg"

export const ProjectsPage = () => {
    return(
        <div className="bodyDiv">
            <h1>Projects</h1>
            <Accordion allowZeroExpanded = 'true'>
                {/* React Website */}
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            React Website
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            This website was created as an excerise in learning a web development framework. React was chosen over competitors such as react due to its high accessible and quick results.
                            This excerise was necessary as through out my time at university my knowledge of web development languages/techniques was based around HTML, CSS, JS and PHP.
                        </p>
                        <form action="https://github.com/JacksonSemmens/ReactWebsite" method="get" target="_blank">
                            <button type="submit" className="codeButton"><img src={githubLogo} className="githubLogo"></img> Code</button>
                        </form>  
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Green Line Test */}
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Green Line Test (Coming Soon)
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            This project was based upon a twitter trend or meme that looked a 
                            photo of a couple and tried to discern which member of the couple believed 
                            that they were settling in the relationship based upon the body language. 
                            This was created with the use of Python as a learning exercise as it was one
                            of the only mainstream languages that I did not learn throughout the course 
                            of my degree.
                        </p>
                        <form action="https://github.com/JacksonSemmens/GreenLineTest/tree/main" method="get" target="_blank">
                            <button type="submit" className="codeButton"><img src={githubLogo} className="githubLogo"></img> Code</button>
                        </form>  
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Naughts and Crosses */}
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Naughts and Crosses (Coming Soon)
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        This project was created to further practice React by creating a simple turn based game, that was completed within a day. 
                        The project is completed, but is yet to be implemented into the website and be tested.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                {/* Naughts and Crosses */}
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            JavaScript Auto List Item (Coming Soon)
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        This project is a small visual studio code extension that I plan on creating in TypeScript that will allow the user to 
                        automatically populate a new item (&lt;li&gt;) in a HTML ordered lists (&lt;ol&gt;) or unordered lists (&lt;ul&gt;). 
                        Allowing for a more effecient workflow when populating lists in a HTML, React or similar projects.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    )
}